import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import userprofile from '../../assets/img/logo.png';
import Swal from 'sweetalert2';
import axios from "axios";
import { UserOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form, Input } from 'antd';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Changepassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const user_id = localStorage.getItem("userid");
  const dev_id = localStorage.getItem("dev_id");
  const navigate = useNavigate();

  const oldOnInputChange = (e) => {
    setOldPassword(e.target.value);
  };

  const OnnewInputChange = (e) => {
    setNewPassword(e.target.value);
  };

  const updatePassword = async () => {
    const url = `${process.env.REACT_APP_API_URL}/change_password.php`;
    const formData = new FormData();
    formData.append('oldPassword', oldPassword);
    formData.append('password', newPassword);
    formData.append('user_id', user_id);
    // formData.append('dev_id', dev_id);
  
    try {
      const response = await axios.post(url, formData);
      console.warn(response);
      if (response.data.success === '1') {
        Swal.fire({
          icon: 'success',
          title: response.data.message,
          // text: 'Password updated successfully.',
          timer: 2000
        }).then(() => {
          // Handle success, e.g., redirect or refresh
          navigate('/');

        });
      }  else {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
            // text: {response.data.massage},
            timer: 2000,
          });
        }
      
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };



  return (
    <>


      <section id="profile" className='chagnepassword'>
        <Container fluid className="p-0">
          <div className="profilepage">
            <div className="profile">
              <img src={userprofile} className='img-fluid' alt="user profile" />
            </div>
          </div>
          <div className="">
            <div className="cardstyle">
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                onFinish={() => {}}
                onFinishFailed={() => {}}
                autoComplete="off"
              >
                <Row>
                  <Col span={24} xl={24} md={24}>
                    <Form.Item
                      name="oldPassword"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your old password!',
                        },
                      ]}
                    >
                      <Input
                        className="username px-2"
                        name="oldPassword"
                        type="password"
                        placeholder="Enter Your Old Password Here"
                        value={oldPassword}
                        onChange={oldOnInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xl={24} md={24}>
                    <Form.Item
                      name="newPassword"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your new password!',
                        },
                      ]}
                    >
                      <Input
                        className="username px-2"
                        name="newPassword"
                        type="password"
                        placeholder="Enter Your New Password Here"
                        value={newPassword}
                        onChange={OnnewInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xl={24} md={24}>
                    <Form.Item
                      name="newPassword"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your new password!',
                        },
                      ]}
                    >
                      {/* <Input
                        className="username px-2"
                        name="newPassword"
                        type="password"
                        placeholder="Re-Enter Your New Password Here"
                        value={newPassword}
                        onChange={OnnewInputChange}
                      /> */}
                    </Form.Item>
                  </Col>
                  <Col span={24} xl={24} md={24}>
                    <Form.Item wrapperCol={{ span: 16 }}>
                      <div className="">
                        <Button type="button" className="w-100 submitbtnpassword" onClick={updatePassword}>
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <ToastContainer />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
