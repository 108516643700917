import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { Container, Form, Row, Col, ListGroup } from "react-bootstrap";
import { toast } from 'react-toastify';
import loading from '../../assets/img/loading-gif.gif';
import filesearch from '../../assets/img/filesearch.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import * as Icon from "react-bootstrap-icons";

const url = new URL(window.location.href);
const gameid = url.searchParams.get('id');
const url1 = new URL(window.location.href);
const name = url1.searchParams.get('name');

export default function Harruf() {
  const blockInvalidChar = e => {
    const invalidKeys = ['e', 'E', '+', '-', '.'];

    if (invalidKeys.includes(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
      e.preventDefault();
    }
  }; const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', "0"];
  const [andarHarufValues, setAndarHarufValues] = useState({});
  const navigate = useNavigate();
  const [baharHarufValues, setBaharHarufValues] = useState({});
  const [users, setUsers] = useState([]);
  const [areCombinationsGenerated, setAreCombinationsGenerated] = useState(false);
  const [Deposit, setDeposit] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appmanagerdata, setAppmanager] = useState(null);
  const [minredeem, setMinredeem] = useState(null);
  const [setminibet1, setMinibet] = useState([]);
  const [MaxbetAmt1, setMaxbet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  const [RandNUmberBat, setRandNUmberBat] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isButtonLoading = useRef(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [loadingbutton, setLoadingbutton] = useState(false);

  const [loading2, setLoading2] = useState(false);
  const market_name = localStorage.getItem("market_name");
  const [profiles, setProfiles] = useState([]); 



  useEffect(() => {
    loaduser();
    app_manager();
    var rString = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    setRandNUmberBat(rString);

  }, [])
  function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const app_manager = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = (`${process.env.REACT_APP_API_URL}/app_setting.php`);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    // formData.append('market_id', gameid);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        var setpoints = response.data.points;
        setMaxpoints(setpoints)
        var min_redeem = response.data.data.min_redeem;
        var mindeposit = response.data.data.min_deposit;
        // console.warn(min_redeem)
        setMinredeem(min_redeem);
        setDeposit(mindeposit);
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setAppmanager(objectRes);
        var setmininumbet = objectRes.HarufMin;
        // console.warn(setmininumbet);
        setMinibet(setmininumbet)
        var setmax_betnumbet = objectRes.HarufMax;
        // console.warn(setmax_betnumbet)
        setMaxbet(setmax_betnumbet)
        setIsLoading(false);

        // console.warn(objectRes);

      })

  }
  const loaduser = async () => {
    const gameid = localStorage.getItem("market_id");

    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = (`${process.env.REACT_APP_API_URL}/num_tbl.php`);
      // console.warn(url);
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('market_id', gameid);
      formData.append('dev_id', dev_id);
      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };
      axios.post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;

          // setMinibet(setmininumbet)
          // setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints)
          // console.warn(setpoints)
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          // console.warn(objectRes);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // setLoading(false);
    }
  }
  useEffect(() => {
    const andarValuesNotEmpty = Object.values(andarHarufValues).some(value => value !== '');
    const baharValuesNotEmpty = Object.values(baharHarufValues).some(value => value !== '');
    setAreCombinationsGenerated(andarValuesNotEmpty || baharValuesNotEmpty);
  }, [andarHarufValues, baharHarufValues]);
  const [inputValues, setInputValues] = useState([]);
  // const [myArray, setMyArray] = useState([]);

  const handleAndarHarufChange = (number, value, type, uniquue) => {

    if (value != '') {
      const betkeyDigit = Math.abs(number % 10);
      const newInputValues = {}; // Create a copy of the array
      newInputValues['betkey'] = betkeyDigit.toString(); // Update the value at the specified index
      newInputValues['betamount'] = value; // Update the value at the specified index
      newInputValues['bettype'] = type; // Update the value at the specified index
      newInputValues['crossing'] = 'no'; // Update the value at the specified index
      newInputValues['session_name'] = 'close'; // Update the value at the specified index

      newInputValues['chkunique'] = uniquue + number; // Update the value at the specified index
      newInputValues['chkunique'] = uniquue + number; // Update the value at the specified index
      // console.warn(newInputValues);
      // setMyArray([...myArray, newInputValues]);
      setInputValues([...inputValues, newInputValues]);
    }

  };
  const handleAndarHarufChange1 = (number, value) => {
    // alert('pppp');
    setAndarHarufValues(prevState => ({
      ...prevState,
      [number]: value
    }));
    setAreCombinationsGenerated(true);
  };
  const handleBaharHarufChange = (number, value) => {
    // alert('pppp');
    setBaharHarufValues(prevState => ({
      ...prevState,
      [number]: value
    }));
    setAreCombinationsGenerated(true);
  };


  const calculateTotalPoints = () => {
    let totalPoints = 0;

    Object.values(andarHarufValues).forEach(value => {
      totalPoints += parseInt(value, 10) || 0;
    });

    Object.values(baharHarufValues).forEach(value => {
      totalPoints += parseInt(value, 10) || 0;
    });

    return totalPoints;
  };
  const handlePlaceBet = () => {
    playGameharruf();


  };

  const playGameharruf = async () => {
    // setShowSubmitButton(false);
    const gameid = localStorage.getItem("market_id");

    setIsButtonDisabled(true);
    const reversedArray = [...inputValues].reverse();
    const filterUniqueByKey = (arr, key) => {
      const uniqueMap = new Map();

      // Process array in reverse order to keep the last entry for each key
      for (let i = arr.length - 1; i >= 0; i--) {
        const item = arr[i];
        uniqueMap.set(item[key], item);
      }

      const uniqueArray = Array.from(uniqueMap.values());
      return uniqueArray.reverse();
    };

    // Filter unique data based on the 'id' key
    const uniqueDataFiltered = filterUniqueByKey(reversedArray, 'chkunique');

    console.warn(uniqueDataFiltered);
    // return;
    var totalPoints = calculateTotalPoints()
    if (users.points < totalPoints) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: 'You Dont Have Sufficient Balance',
        timer: 2000,
        icon: 'error'
      });
      return;
    }
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    // console.warn(setminibet1);
    const miniBet = parseFloat(appmanagerdata.HarufMin);
    const maxBet = parseFloat(appmanagerdata.HarufMax);
    let isValidBet = true;

    if (!isValidBet) {
      return;
    }
    var totalPoints = calculateTotalPoints()
    if (users.points < totalPoints) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: 'You Dont Have Sufficient Balance',
        timer: 2000,
        icon: 'error'
      });
      return;
    }
    setIsButtonVisible(false);
    // if (!isButtonLoading.current) {
    //   isButtonLoading.current = true;
    //   setIsButtonDisabled(true);
    //   setTimeout(() => {
    //     setIsButtonDisabled(false);
    //     isButtonLoading.current = false;
    //   }, 1000);
    // }
    try {
      setLoadingbutton(true);

      // setLoading2(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/play_game.php`, {
        user_id: user_id,
        dev_id: dev_id,
        market_id: gameid,
        app_id: process.env.REACT_APP_API_ID,
        BetList: uniqueDataFiltered,
        dev_model: "web",
        market_type: 'mainMarket',
        devName: "sumsang",
      });

      if (response.data.success == 1) {
        // setTimeout(() => {
        // setLoading2(false);
        // setIsButtonDisabled(true);

        // setShowSubmitButton(false);
        // setIsButtonDisabled(true)

        // }, 1000);
        // console.warn(response.data.success)
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: response.data.message,
          icon: 'success',
          timer: 2500
        })
          .then((result) => {
            navigate('/');
          })
      } else {
        // setIsButtonDisabled(false);
        toast.error(response.data.message);
        return;
      }
      // return;
    } catch (error) {
      console.error('Game store:', error);
      toast.error('An error occurred while Game store. Please try again later.');
    }
    finally {
      setLoadingbutton(false);
      // setLoading2(true);
    }
  };
  const [arrayToSend, setArrayToSend] = useState([1, 2, 3, 4, 5]);
  useEffect(() => {
    Getprofile();
  }, []);

  const Getprofile = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  const handelclick = () => {
    // alert('hhh')
    navigate(-1);
  }
  return (
    <>
    <div className="header-top">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2} s={2}>
              <Link onClick={handelclick} class="arrowlink">
                <FaArrowLeft />
              </Link>
            </Col>
            <Col xs={8} md={8} s={8}>
              <div className="menuicon d-flex justify-content-center">
                <div className="d-flex">
                  <div class="headericonarrow">
                  </div>
                  {market_name}
                </div>

              </div>
            </Col>

            <Col xs={2} md={2} s={2}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center wallet">
                  <Icon.Wallet /> :
                  {profiles.credit}
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section id="playgame" className="margin-bottom-88 mb-5 fixed headerchat">
        <Container fluid>
          <div className="d-flex justify-content-between px-3 ">
            {/* <div className="points">
              <h5>Points Remaining : {Math.max(0, users.points) - calculateTotalPoints()}</h5>
            </div>
            <div className="points">
              <h5>Points Added</h5>
              <p>{calculateTotalPoints()}</p>
            </div> */}
          </div>
          <div className="andarharuf">
            <p className='gametitle '>Andar Haruf</p>
            <div className='d-flex flex-wrap justify-content-between'>
              {numbers.map(number => (
                <div key={number} className='cardview'>
                  <div className={`number ${andarHarufValues[number] ? 'green' : ''}`}>
                    {number}
                  </div>
                  {/* <input type="number" className='form-input' onChange={e => handleAndarHarufChange(number, e.target.value)} autoFocus /> */}
                  <input className='form-input'
                    type='text'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    onPaste={(e) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '').slice(0, 2);
                      document.execCommand('insertText', false, pastedText);
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                      handleAndarHarufChange1(number, inputValue, 8);
                    }}
                    onInput={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                      e.target.value = inputValue; // Ensure the input value is updated
                      handleAndarHarufChange1(number, inputValue, 8);
                    }}
                    onBlur={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                      e.target.value = inputValue; // Ensure the input value is updated
                      handleAndarHarufChange(number, inputValue, 9, "A");
                    }}
                    autoFocus />
                </div>
              ))}
            </div>
          </div>


          <div className="andarharuf mt-3">
            <p className='gametitle'>Bahar Haruf</p>
            <div className='d-flex flex-wrap justify-content-between'>
              {numbers.map(number => (
                <div key={number} className='cardview'>
                  <div className={`number ${baharHarufValues[number] ? 'green' : ''}`}>
                    {number}
                  </div>
                  {/* <input type="number" className='form-input' onKeyDown={blockInvalidChar} onChange={e => handleBaharHarufChange(number, e.target.value)} autoFocus /> */}
                  <input
                    className='form-input'
                    type='text'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    onPaste={(e) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '').slice(0, 2);
                      document.execCommand('insertText', false, pastedText);
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                      handleBaharHarufChange(number, inputValue);
                    }}
                    onInput={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                      e.target.value = inputValue; // Ensure the input value is updated
                      handleBaharHarufChange(number, inputValue);
                    }}
                    onBlur={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                      e.target.value = inputValue; // Ensure the input value is updated
                      handleAndarHarufChange(number, inputValue, 10, "B");
                    }}

                    autoFocus
                  />
                </div>
              ))}
            </div>
          </div>
          {/* </div> */}
          < div className="totalpoints" >
            {/* <div>
          <p className='mb-0'>₹<span className='ms-2'>{calculateTotalPoints()}/-</span></p>
        </div> */}
            {/* {showSubmitButton && <button className="btn-add" onClick={handlePlaceBet} disabled={!areCombinationsGenerated}>Place Bet</button>} */}
            {/* <button className="btn-add" onClick={handlePlaceBet} disabled={loadingbutton || !areCombinationsGenerated}>Place Bet
              {loadingbutton && <Spinner animation="border" />}
            </button> */}
            <div class="totalpoints d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-between align-items-center">
                <p class="text-black">₹</p>
                <p class="ms-1 text-black">{calculateTotalPoints()} /-</p>
              </div>
              {users.remaining_time_in_seconds > 0 ?
              <button class="btn-add" onClick={handlePlaceBet} disabled={loadingbutton || !areCombinationsGenerated}>
                <span>Submit</span>
                {loadingbutton && <Spinner animation="border" />}
              </button>
              :
              <button className='btn-add'>
                <span >Bet Has Been Closed</span>
              </button>
            }
            </div>
          </div >
        </Container>
      </section >




      {/* <section id="harruf">
        

      </section> */}

      <ToastContainer />
      {
        loading2 && (
          <div className="spinner-wrapper">
            <div className="loadernew2"></div>
          </div>
        )
      }

    </>
  );
}


