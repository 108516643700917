import * as Icon from "react-bootstrap-icons";
import { FaUser, FaWallet, FaHistory, FaRegCreditCard, FaPlayCircle, FaShareAlt } from "react-icons/fa";
import { MdHelpCenter, MdStarRate, MdQuestionAnswer, MdKey } from "react-icons/md";
// import { MdQuestionAnswer } from "react-icons/md";
import { GrBarChart } from "react-icons/gr";
import { LuLogOut } from "react-icons/lu";
import { IoWarning } from "react-icons/io5";
import { GiLaurelsTrophy } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { RiBankCard2Line } from "react-icons/ri";
// import { MdQuestionAnswer } from "react-icons/md";
const user_id = localStorage.getItem("userid");
const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: "Check out this app!",
      url: "https://dlmatka.com/API/public/api",
    })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
    const unsupportedBrowserMessage = `
      Sharing is not supported in this browser.
      To share, please manually copy and paste the following link:
      https://dlmatka.com/API/public/api
    `;
    alert(unsupportedBrowserMessage);
  }
};

const Sidebardata = [
  {
    title: "My Profile",
    path: "/Profile",
    icon: <FaUser />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },

  {
    title: "My Wallet",
    path: "/Wallet",
    icon: <FaWallet />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Bid History",
    path: "/Bid-history",
    // path :"/Market-ret",
    icon: <FaHistory />
    ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Win History",
    path: "/Win-History",
    icon: <GiLaurelsTrophy />
    ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Add Bank",
    path: "/Add-Bank-Details",
    icon: <RiBankCard2Line />
    ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },

  {
    title: "Withdrawal Methods",
    path: "/WithdrawAmount",
    icon: <BiMoneyWithdraw />
    ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Game Rates",
    path: "/Market-rate",
    icon: <GrBarChart />
    ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },



  //   {
  //     title:" History",
  //     path:"/History",
  //     icon:<Icon.ClockHistory/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },
  //   {
  //     title:"Whatsapp Group Join ",
  //     path:"https://api.whatsapp.com/send?phone=6367529290 ",
  //     icon:<Icon.Whatsapp/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },


  //   {
  //     title:"Result History",
  //     path:"/Resulthistory",
  //     icon:<Icon.HourglassSplit/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },
  //   {
  //     title:"Terms And Condtion",
  //     path:"/Termsandcondition",
  //     icon:<Icon.JournalCheck/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },

  {
    title: "Help",
    path: "tel:+9694700216",
    icon: <MdHelpCenter />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },

  {
    title: "FAQ",
    path: "FAQ",
    icon: <MdQuestionAnswer />


    ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "How to use",
    path: "https://www.youtube.com/watch?v=JaUCiKTNBxQ",
    // <iframe src="https://www.youtube.com/watch?v=JaUCiKTNBxQ" frameborder="0">How to use</iframe>


    icon: <FaPlayCircle />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Instruction",
    path: "/Instruction",
    icon: <IoWarning />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Notice",
    path: "/Notification",
    icon: <Icon.FileEarmarkPdf />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Share our App",
    // onClick: handleShare,
    icon: <FaShareAlt />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Rate us",
    // path:"https://play.google.com/store/apps/details?id=dlmatka1.com",

    icon: <MdStarRate />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },


  {
    title: "Change password",
    path: "Changepassword",
    icon: <MdKey />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },



  //   {
  //     title:"Game Posting",
  //     path:"/Gameposting",
  //     icon:<Icon.Chat/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },
  //   {
  //     title:"Rate Our App",
  //     path:"https://play.google.com/store/apps/details?id=com.babaji.matka",
  //    icon:<Icon.Phone/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },

  {
    title: "Logout",
    // path:"/Login",
    icon: <LuLogOut />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },


];
export default Sidebardata;