import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from 'antd';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PlayJantrl = () => {
  const url = new URL(window.location.href);
  const gameid = url.searchParams.get('id');

  const [inputValues, setInputValues] = useState(Array(12).fill(''));
  const [betnumbers, setBetNumbers] = useState(Array(12).fill(''));
  const [areAnyInputsEmpty, setAreAnyInputsEmpty] = useState(false);

  const [users, setUsers] = useState({});
  const [totalPoints, setTotalPoints] = useState(0);
  const [loadingbutton, setLoadingbutton] = useState(false);

  const navigate = useNavigate();

  const blockInvalidChar = (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  const handleInputChange = (e, index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = e.target.value.slice(0, 2);
    setInputValues(newInputValues);
    const newTotalPoints = newInputValues.reduce((acc, value) => acc + Number(value), 0);
    setTotalPoints(newTotalPoints);
  };
  const handleButtonClick = () => {
   
  
    // Filter out null values and empty strings before checking for equality
    const nonNullInputValues = inputValues.filter(value => value !== null && value !== '');
    const nonNullBetNumbers = betnumbers.filter(value => value !== null && value !== '');
  
    // Check if the filtered arrays are empty
    if (nonNullInputValues.length === 0 || nonNullBetNumbers.length === 0) {
      toast.error('Please Enter a Valid Digit Number.');
      return;
    }
  
    playGame();
  };
  
  
  


  useEffect(() => {
    const emptyInputs = inputValues.every((value) => value.trim() === '');
    setAreAnyInputsEmpty(emptyInputs);
  }, [inputValues]);

  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const gameid = localStorage.getItem("market_id");

    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = (`${process.env.REACT_APP_API_URL}/num_tbl.php`);
      // console.warn(url);
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('market_id', gameid);
      formData.append('dev_id', dev_id);
      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };
      axios.post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;

          // setMinibet(setmininumbet)
          // setMaxbet(setmax_betnumbet)
          // setMaxpoints(setpoints)
          // console.warn(setpoints)
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          // console.warn(objectRes);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // setLoading(false);
    }
  }

  const playGame = async () => {
    const gameid = localStorage.getItem("market_id");
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');

    const isInvalidBet = inputValues.some((value) => {
      const numericValue = parseFloat(value);
      const miniBet = parseFloat(users.mini_bet);
      const maxBet = parseFloat(users.max_bet);

      if (numericValue < miniBet || numericValue > maxBet) {
        toast.error(`Invalid bet value for Jodi. Please check your input.`);
        return true;
      }

      return false;
    });

    if (isInvalidBet || users.points < totalPoints) {
      toast.error('Invalid bet or insufficient balance.');
      return;
    }

    try {
      setLoadingbutton(true);

      const betList = inputValues.map((value, index) => ({
        betkey: betnumbers[index],
        betamount: value || 0,
        bettype: 2,
        session_name: "close",
      })).filter(item => item.betamount > 0);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/play_game.php`, {
        user_id: user_id,
        dev_id: dev_id,
        market_id: gameid,
        app_id: process.env.REACT_APP_API_ID,
        BetList: betList,
        dev_model: "web",
        market_type: 'mainMarket',
        devName: "sumsang",
      });

      if (response.data.success === '1') {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: 'success',
          timer: 2500,
        }).then(() => {
          // navigate('/');
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error playing game:', error);
    } finally {
      setLoadingbutton(false);
    }
  };

  return (
    <>
      <section id="playgame" className="margin-bottom-88 mb-5 fixed headerchat">
        <Container fluid>
          <Row className='playjantri'>
            {Array.from({ length: 12 }, (_, index) => (
              <Col md={4} xs={4} className='mb-2' key={index}>
                <input
                  type='number'
                  onKeyDown={blockInvalidChar}
                  placeholder={`Jodi ${index + 1}`}
                  className='playjantmargin'
                  onChange={(e) => {
                    const inputValue = e.target.value.slice(0, 2); // Limit input to 2 characters
                    setBetNumbers((betnumbers) =>
                      betnumbers.map((val, i) => (i === index ? inputValue : val))
                    );
                  }}
                  value={betnumbers[index]}
                  autoFocus={index === 0}
                />

                <input
                  type='number'
                  onKeyDown={blockInvalidChar}
                  placeholder={`Point ${index + 1}`}
                  className='playjantmargin'
                  autoFocus={index === 0}
                  value={inputValues[index]}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={loadingbutton}

                />
              </Col>
            ))}
          </Row>
          <div className='position-relative'>
            {/* <div className='totalpoints d-flex justify-content-between align-items-center'> */}
              <div className="totalpoints d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="text-black">₹</p>
                  <p className="ms-1 text-black">{totalPoints} /-</p>
                </div>
                {users.remaining_time_in_seconds > 0 ?
                <button className="btn-add" onClick={handleButtonClick} disabled={loadingbutton || areAnyInputsEmpty}>
                  <span>Submit</span>
                  {loadingbutton && <Spinner animation="border" />}
                </button>
                :
                   <button className='btn-add'>
                     <span >Bet has been closed</span>
                   </button>
                 }
              </div>
            {/* </div> */}
          </div>
        </Container>
        <ToastContainer />
      </section>
    </>
  );
};

export default PlayJantrl;
