import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import SidebarData from '../Sidebar/SidebarData';
import profile from '../../assets/img/logo.png';
import logotb from '../../assets/img/logotb.png';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineRefresh } from "react-icons/md";
import SidebarDataOne from '../Sidebar/SidebarDataOne'



import Swal from "sweetalert2";

export default function Header() {
  const [scrolltopdata, setscrolltopdata] = useState('');
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState([]);
  const [gameid, setGameid] = useState(localStorage.getItem('gameid') || '');
  const location = useLocation();
  const [path, setPath] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 1) {
        setscrolltopdata('');
      } else {
        setscrolltopdata('scrolled');
      }
    });
  }, [])
  const isPlayPage = location.pathname === '/';
  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
    console.log('page to reload');
  };

  // Assuming you have SweetAlert library included in your project

  const logout = (e) => {
    e.preventDefault();

    // Display a SweetAlert confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be log out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log Out !'
    }).then((result) => {
      if (result.isConfirmed) {
        // Uncomment the following line if you want to remove a specific item from localStorage
        // localStorage.removeItem('user_id');

        // Clear all entries from localStorage
        localStorage.clear();
        // navigate(-1);
        // Redirect to the root URL
        navigate('/Login');
      }
    });
  }

  useEffect(() => {

    const updatePath = () => {
      const segment = window.location.pathname.split("/");

      setPath(segment);

    };
    updatePath();
    const intervaldId = setInterval(updatePath, 1000);
    return () => {
      clearInterval(intervaldId);
    }
  }, []);

  // useEffect(() => {
  //   const user_id = localStorage.getItem('userid');
  //   if (user_id == null) {
  //     window.location.href = '/Login';
  //   }
  //   const devid = localStorage.getItem('dev_id');
  //   if (devid == null) {
  //     window.location.href = '/Login';
  //   }

  //   // loaduserr()
  // }, []);

  useEffect(() => {
    const user_id = localStorage.getItem('userid');
    if (user_id == null) {
      window.location.href = '/Login';
    }
    const devid = localStorage.getItem('dev_id');
    if (devid == null) {
      window.location.href = '/Login';
    }

  }, [gameid]);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        const res1 = response.data.success;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
        if (res1 == 3) {

          localStorage.clear();
          navigate('/Login');
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const newGameid = url.searchParams.get('id');

    if (newGameid) {
      setGameid(newGameid);
      localStorage.setItem('gameid', newGameid);


      // alert(newGameid)
    }
  }, [window.location.href]);
  // const location = useLocation();
  const handleClickclose = (id, name) => {
    if ("vibrate" in navigator) {
      // vibration API supported
      navigator.vibrate(30);
    }
  };

  return (
    <div className='heightall'>
      <div className={`header-top  ${scrolltopdata}`}>
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2} s={2}>
              <Icon.List className='menuicon' ml={2} onClick={handleClick} />
            </Col>
            <Col xs={5} md={5} s={5}>
              <div className="menuicon d-flex justify-content-end text-center">
                <div className="d-flex">

                  <h6 className=' text-center mb-0' style={{ marginTop: "8px" }}> MILAN STAR </h6>



                </div>


              </div>
            </Col>

            <Col xs={2} md={2} s={2}>
              {profiles && profiles.is_playstore == 0 ?
              ""
              :
               ""
            }
              {profiles && profiles.is_playstore == 1?
              <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center wallet">
                <Icon.Wallet /> :
                {profiles.credit}
              </div>

            </div>
              :
                ""
            }
              </Col>
          </Row>
        </Container>
      </div>
      <div className={open ? 'sidebar is-toggle' : 'sidebar'}>
        <div className="profileimage">
          <Button className="closebtn" onClick={handleClick}>
            &times;
          </Button>

          <div className="d-flex align-items-center justify-content-between" >
            <div className="profilephoto " >
              <img src={profile} />
            </div>
            <div className="profiledetails">
              <h3>{profiles.name}</h3>
              <h4><strong>ID : </strong>{profiles.mob}</h4>
            </div>
          </div>


        </div>
        {profiles && profiles.is_playstore == 0 ?
          <ul className=" menulist">
            {SidebarDataOne.map((val) => (
              <li>
                {val.title == 'Logout' ? (
                  <Link to={val.path} onClick={logout} className={val.ownclass}>
                    <div className="iconmenu">{val.icon}</div>
                    {val.title}
                    {val.hinditext}
                  </Link>
                ) : val.title === 'Share our App' ? (
                  <Link to="#" onClick={val.onClick} className={val.ownclass}>
                    <div className="iconmenu">{val.icon}</div>
                    <div className="menulistsidebar">
                      {val.title}
                      <small> {val.hinditext}</small>
                    </div>
                  </Link>
                ) : (
                  <Link to={val.path} onClick={handleClick} className={val.ownclass}>
                    <div className="iconmenu">{val.icon}</div>
                    <div className="menulistsidebar">
                      {val.title}
                      <small> {val.hinditext}</small>
                    </div>
                  </Link>
                )}
              </li>
            ))}
          </ul>
          :
          <ul className=" menulist">
            {SidebarData.map((val) => (
              <li>
                {val.title == 'Logout' ? (
                  <Link to={val.path} onClick={logout} className={val.ownclass}>
                    <div className="iconmenu">{val.icon}</div>
                    {val.title}
                    {val.hinditext}
                  </Link>
                ) : val.title === 'Share our App' ? (
                  <Link to="#" onClick={val.onClick} className={val.ownclass}>
                    <div className="iconmenu">{val.icon}</div>
                    <div className="menulistsidebar">
                      {val.title}
                      <small> {val.hinditext}</small>
                    </div>
                  </Link>
                ) : (
                  <Link to={val.path} onClick={handleClick} className={val.ownclass}>
                    <div className="iconmenu">{val.icon}</div>
                    <div className="menulistsidebar">
                      {val.title}
                      <small> {val.hinditext}</small>
                    </div>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        }
      </div>
      <div className={`sidebar-overlay ${open == true ? 'active' : ''}`} onClick={handleClick}></div>
      {profiles && profiles.is_playstore == 0 ?
        ""
        :
        <div onClick={refreshPage} className="refreshbtn">
          <IoMdRefresh onClick={handleClickclose} />
        </div>
      }
    </div>
  );
}
