import React, { useState, useEffect } from "react";
import { Col, Row, Form, Input } from "antd";
import axios from "axios";
import logo from "../../assets/img/logo.png";
import { Card, Container } from "react-bootstrap";
import { PhoneOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import * as Icon from 'react-bootstrap-icons';
import { Link } from "react-router-dom";




const Profile = () => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };


  const [users, setUsers] = useState("")






  return (
    <div className="margin-bottom-88 mb-0 Profile">

      <Container>
        {profiles.length === 0 ? (
          <p>No profiles available</p>
        ) : (
          <>
            <div className="user_profile">
              <div className="logoImg">
                <img src={logo} alt="Logo" className="img-fluid" />
              </div>
              <Card>
                <Row justify={"center"} className="">
                  <Col span={24} xl={24} lg={24} md={24} sm={24}>
                    <Form.Item
                      name="username"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: "Please input your Name!",
                        },
                      ]}
                    >
                      <label>Name:</label>
                      <div className="w-100">
                        <Input
                          className="username w-100"
                          name="mobileNum"
                          placeholder="Name"
                          value={profiles.name}
                          prefix={
                            <UserOutlined
                              className="site-form-item-icon"
                              style={{ padding: "0 8px" }}
                            />
                          }
                        />
                      </div>
                    </Form.Item>
                  </Col>


                  <Col span={24} xl={24} lg={24} md={24} sm={24}>
                    <Form.Item
                      name="username"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: "Please input your Number!",
                        },
                      ]}
                    >
                      <label>Mobile:</label>
                      <div className="w-100">
                        <Input
                          className="username w-100"
                          name="mobileNum"
                          value={profiles.mob}
                          placeholder="Mobile Number"
                          prefix={
                            <PhoneOutlined
                              className="site-form-item-icon call"
                              style={{ padding: "0 8px" }}
                            />
                          }
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  {/* <Col span={24} xl={24} md={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <label>Referral Code:</label>
                      <div className="w-100">
                        <Input
                          className="username w-100"
                          name="mobileNum"
                          value={profiles.refCode}
                          placeholder="Refer Code"
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          style={{ padding: "0 8px" }}
                        />
                      </div>
                    </Form.Item>
                  </Col> */}
                </Row>
              </Card>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default Profile;
